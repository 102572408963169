import React, { Fragment } from 'react'
import { StaticQuery, graphql } from 'gatsby'

import styled from 'styled-components'

import Back from '../../components/caseStudies/back'
import BrandBox from '../../components/caseStudies/brandBox'
import ContentWrapper from '../../components/share/contentWrapper'
import Details from '../../components/caseStudies/details'
import Header from '../../components/caseStudies/header'
import IntroRow from '../../components/share/introRow'
import Layout from '../../components/layout'
import Quote from '../../components/share/quote'
import SpeakToTeam from '../../components/share/speakToTeam'
import TypeFormButton from '../../components/share/typeFormButton'

import brandIcon from '../../images/brandLogos/theAgency.svg'

const query = graphql`
  query {
    header: file(relativePath: { eq: "caseStudies/header-agency.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    agentImage: file(
      relativePath: { eq: "brandAgentIcon/john_cunningham.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const TheAgency = props => {
  return (
    <Layout>
      <StaticQuery
        query={query}
        render={data => (
          <Fragment>
            <Header
              background={data.header.childImageSharp.fluid}
              year="2017"
              iconStyle={{ width: '100px' }}
              url="www.harrisre.com.au"
              brandName="The Agency"
              icon={brandIcon}
              overlayColor="rgba(0, 0, 0, 0.7)"
              content={
                <Fragment>
                  Founded in just 2017, a modern take on what brands have to
                  offer agents emerged to take the industry (and high performing
                  agents) by storm.
                </Fragment>
              }
            />

            <ContentWrapper style={{ margin: '40px auto' }}>
              <Details
                sizes={['National', 'Boutique']}
                integrations={['CRMs', 'Pinters', 'Newspapers']}
                customTitle="# OF SALES IN 2018"
                customData={['1,400+']}
              />
            </ContentWrapper>

            <ContentWrapper style={{ margin: '40px auto' }}>
              <Quote
                descStyle={{ width: '100%' }}
                desc={`“I can’t believe it’s been this easy a transition.”`}
              />
              <IntroRow
                descWidth="85%"
                title="Taking advantage of a slam dunk offering is still a complicated decision."
                content={
                  <Fragment>
                    <Paragraph>
                      Across the network of real estate media suppliers, there’s
                      the good, the bad and the ugly. And if you’re already
                      functioning exceptionally as a business and experiencing
                      massive growth, the thought of changing one of the big
                      cogs out from the machine that is your business could be
                      terrifying.
                    </Paragraph>
                    <Paragraph>
                      It takes guts to make a decision like that, but based on
                      the offering Realhub was able to provide and the
                      advantages presented The Agency, young and controversial
                      group with everything to prove decided it was time to move
                      to a better way of getting things done.
                    </Paragraph>
                    <Paragraph>
                      Every new customer we onboard comes with a unique set of
                      challenges, a unique set of requirements but when the team
                      at Realhub saw a chance to align with a brand they truly
                      see as disruptive offering to a lot of agents we knew the
                      juice would be worth the squeeze.
                    </Paragraph>
                    <Paragraph>
                      Building out the missing peices over the course of a two
                      month project we quickly adapted to new workflows and fit
                      existing products/platforms into our own to accomodate.
                    </Paragraph>
                  </Fragment>
                }
              />
            </ContentWrapper>

            <ContentWrapper style={{ margin: '40px auto' }}>
              <Quote
                desc={`“I’m sure we (ok I) hound you guys a lot. Wanted to say I appreciate all your help all the time, that’s all!”`}
                descStyle={{ width: '100%' }}
                agentName="Jenny Lam"
                title="General Manager"
                agencyName="TheAgency"
              />
            </ContentWrapper>

            <ContentWrapper style={{ margin: '40px auto' }}>
              <IntroRow
                descWidth="65%"
                content={
                  <Fragment>
                    <Paragraph>
                      No great onboarding is possible without a great leadership
                      team. It requires efficient communication and fast
                      thinkers to take a real estate brand from one product to
                      the next, and with the team at the Agency aligning with a
                      lot of our own company values, of being fast acting and
                      performing high impact decision making at fast speeds we
                      saw the group move over to congratulations from the
                      agencies who immediately saw the potential our future
                      relationship held.
                    </Paragraph>
                    <Paragraph>
                      Realhub continues to provide the support and communication
                      to all of their offices that they experienced across the
                      migration and look forwad to working together for many
                      years to come.
                    </Paragraph>
                    <Paragraph>
                      Get ahead of the curve.{' '}
                      <TypeFormButton
                        text="Join the team at Realhub today."
                        plain
                      />
                    </Paragraph>
                  </Fragment>
                }
              />
            </ContentWrapper>

            <ContentWrapper>
              <SpeakToTeam
                uid="WpgxlK"
                avatar="https://labs-assets.typeform.com/cui/avatars/WpgxlK/cde0f362a9e74bff7bfc.png"
              />
            </ContentWrapper>

            <ContentWrapper style={{ margin: '40px auto' }}>
              <Back />
              <Flex>
                <BrandBox
                  brandName="BelleProperty"
                  iconStyle={{ height: '50px' }}
                />

                <BrandBox brandName="Harris" />

                <BrandBox brandName="LJHooker" />
              </Flex>
            </ContentWrapper>
          </Fragment>
        )}
      />
    </Layout>
  )
}

export default TheAgency

const Paragraph = styled.p`
  font-family: Open Sans;
  margin-bottom: 30px;
`

const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
`
